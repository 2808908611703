import { reactive } from 'vue'

export const useRegions = ({ detail }) => {
	const regions = reactive({})

	const deleteRegion = (id) => {
		detail.value.changeInitialState()
		delete regions[id]
	}

	const addRegion = (data) => {
		detail.value.changeInitialState()
		const { title } = data
		regions[data.id] = { title }
	}

	const getRegionsIds = () => {
		return Object.keys(regions)
	}

	return {
		regions,
		deleteRegion,
		getRegionsIds,
		addRegion
	}
}
