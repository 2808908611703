<template lang="pug">
TheModal(:show="show" @update:show="$emit('update:show', $event)")
	BaseInput(@update:modelValue="onSearchRegion" placeholder='Поиск участка' v-model="search" icon="search").mb-4
	TheList(
		v-if="Object.keys(regions).length > 0"
		:map="preparedRegions"
		checkboxsList
		@onClick="addRegion"
		@onDelete="deleteRegion"
		@onConfirm="onConfirm"
		:key="preparedRegions"
		:search="search"
		)
	.flex.flex--jcc(v-else)
		span() Участки не найдены
</template>

<script>
import TheModal from '../TheModal'
import TheList from '../TheList'
import BaseInput from '@/components/ui/BaseInput.vue'
import { useAlert } from '../../modules/composition/useAlert'
import { onMounted, reactive, ref, watch, onUpdated } from 'vue'
import { useApi } from '@/modules/api'

export default {
	name: 'ModalRegions',
	components: { TheList, TheModal, BaseInput },
	props: {
		show: {
			type: Boolean,
			required: true
		},
		selectedRegions: {
			type: Object,
			default: () => {}
		}
	},
	emits: ['update:show', 'add', 'delete'],
	setup (props, { emit }) {
		const regions = reactive({})
		const search = ref('')
		watch(
			() => props.show,
			async () => {
				if (!props.show) {
					search.value = ''
					await getAllRegions()
				}
			}
		)
		const { errorAlert } = useAlert()
		const onSearchRegion = async (value) => {
			const { get, data, error } = useApi(`dictionary/fisherRegion${value ? `/search?&search=${value}` : ''}`)
			await get()
			if (error.value) {
				errorAlert(`Не удалось получить участки! ${error.value?.message}`)
			} else {
				Object.keys(regions).forEach(key => delete regions[key])
				data.value.forEach(({ id, nameShort, name }) => {
					regions[id] = { left: nameShort, fullName: name }
				})
				const visebleIds = data.value.map(item => item.id)
				for (const key in preparedRegions.value) {
					if (visebleIds.includes(parseInt(key))) {
						preparedRegions.value[key].viseble = true
					} else {
						preparedRegions.value[key].viseble = false
					}
				}
			}
		}
		const getAllRegions = async () => {
			const { get, data } = useApi('/dictionary/fisherRegion')

			await get()
			data.value.forEach(({ id, nameShort, name }) => {
				regions[id] = { left: nameShort, fullName: name }
			})
		}

		const preparedRegions = ref({})

		const prepareRegions = (regions) => {
			const result = {}
			const selectedRegions = Object.keys(props.selectedRegions)
			for (const key in regions) {
				result[key] = {
					...regions[key],
					id: key,
					checked: false,
					viseble: true
				}
			}
			selectedRegions.forEach(id => {
				if (result?.[id]) {
					result[id].checked = true
				}
			})
			return result
		}
		onMounted(async () => {
			await getAllRegions()
			preparedRegions.value = prepareRegions(regions)
		})
		onUpdated(() => {
			preparedRegions.value = prepareRegions(regions)
		})
		const onConfirm = (arrIdsAdd, arrIdsDelete) => {
			arrIdsAdd.forEach(id => {
				addRegion(id)
			})
			arrIdsDelete.forEach(id => {
				deleteRegion(id)
			})
			emit('update:show', false)
		}
		const addRegion = (id) => {
			const { fullName: title } = regions[id]
			emit('add', { id, title })
		}
		const deleteRegion = (id = null) => {
			if (id) {
				emit('delete', id)
			}
		}
		return {
			regions,
			search,
			onSearchRegion,
			addRegion,
			preparedRegions,
			deleteRegion,
			onConfirm
		}
	}
}
</script>
